import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CAMFIL_PDF_DEFAULT_STYLE,
  CAMFIL_PDF_FONTS,
  CAMFIL_PDF_LABELS_FACTORY,
  CAMFIL_PDF_MAKER,
} from 'camfil-core/configurations/camfil-injection-keys';
import { CamfilCustomersFacade } from 'camfil-core/facades/camfil-customers.facade';
import { formatCamfilDate } from 'camfil-core/pipes/camfil-date.pipe';
import { formatSummaryPrice } from 'camfil-core/pipes/camfil-price-summary.pipe';
import { UnitHelper } from 'camfil-models/camfil-ahu/unit/unit.helper';
import { CamfilCustomer } from 'camfil-models/camfil-customer/camfil-customer.model';
import { PdfHelper } from 'camfil-shared/cam-pdf/models/pdf.helper';
import { CamfilPdfDefinitions } from 'camfil-shared/cam-pdf/models/pdf.interface';
import {
  CamfilPdfHeaderData,
  CamfilPdfInfoData,
  CamfilPdfMetaData,
  CamfilPdfTableData,
  CamfilPdfTableRowData,
} from 'camfil-shared/cam-pdf/models/pdf.model';
import { CAMFIL_EMPTY_OPTION_LABEL } from 'camfil-shared/camfil-constants';
import { ContentTable, TDocumentDefinitions, TableCell } from 'pdfmake/interfaces';

import { AccountFacade } from 'ish-core/facades/account.facade';
import { formatPrice } from 'ish-core/models/price/price.pipe';
import { User } from 'ish-core/models/user/user.model';
import { InjectSingle } from 'ish-core/utils/injection';

@Injectable({ providedIn: 'root' })
export class CamfilPdfService {
  private pdfMakeInstance: Awaited<typeof this.pdfMaker>;
  private pdfCurrentCustomer: CamfilCustomer;
  private pdfCurrentPriceDisplayType: 'gross' | 'net';
  private pdfCurrentUser: User;

  constructor(
    @Inject(CAMFIL_PDF_MAKER)
    private readonly pdfMaker: InjectSingle<typeof CAMFIL_PDF_MAKER>,
    @Inject(CAMFIL_PDF_FONTS)
    private readonly pdfFonts: InjectSingle<typeof CAMFIL_PDF_FONTS>,
    @Inject(CAMFIL_PDF_LABELS_FACTORY)
    private readonly pdfLabelsFactory: InjectSingle<typeof CAMFIL_PDF_LABELS_FACTORY>,
    @Inject(CAMFIL_PDF_DEFAULT_STYLE)
    private readonly pdfDefaultStyle: InjectSingle<typeof CAMFIL_PDF_DEFAULT_STYLE>,
    // private appFacade: AppFacade,
    private accountFacade: AccountFacade,
    private customersFacade: CamfilCustomersFacade,
    private translate: TranslateService
  ) {
    this.initializePdfMake();
  }

  private async initializePdfMake() {
    this.pdfMakeInstance = await this.pdfMaker;
    this.pdfMakeInstance.fonts = this.pdfFonts;
    this.accountFacade.user$.subscribe(user => (this.pdfCurrentUser = user));
    this.accountFacade.userPriceDisplayType$.subscribe(priceType => (this.pdfCurrentPriceDisplayType = priceType));
    this.customersFacade.currentCustomer$.subscribe(customer => (this.pdfCurrentCustomer = customer));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addSuffix<T extends Record<string, any>>(obj: T, suffix: string = ': '): T {
    const suffixWithoutSpace = suffix.trimEnd();
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        if (Array.isArray(value)) {
          return [
            key,
            value.map(v => (v.endsWith(suffix) || v.endsWith(suffixWithoutSpace) ? `${v} ` : `${v}${suffix}`)),
          ];
        }
        return [key, value.endsWith(suffix) || value.endsWith(suffixWithoutSpace) ? `${value} ` : `${value}${suffix}`];
      })
    ) as T;
  }

  get pdfLabels() {
    return this.addSuffix(this.pdfLabelsFactory());
  }

  get pdfDate() {
    return formatCamfilDate(new Date(), this.translate.currentLang);
  }

  get pdfHeaderData(): CamfilPdfHeaderData {
    return {
      printDate: this.pdfDate,
      printBy: `${this.pdfCurrentUser.firstName} ${this.pdfCurrentUser.lastName}`,
    };
  }

  pdfHeaderContent(
    meta: CamfilPdfMetaData,
    data: CamfilPdfHeaderData = this.pdfHeaderData,
    labels = this.pdfLabels
  ): TDocumentDefinitions['content'] {
    return {
      pageBreak: meta?.pageNumber >= 1 ? 'before' : undefined,
      columns: [
        { image: 'logo', width: 200 },
        {
          style: 'dateInfo',
          layout: 'noBorders',
          margin: [0, 20, 0, 0],
          table: {
            widths: ['*', 'auto'],
            body: [
              [labels.printDate, { text: data.printDate, alignment: 'left', bold: true }],
              [labels.printedBy, { text: data.printBy, alignment: 'left', bold: true }],
            ],
          },
        },
      ],
    };
  }

  get pdfInfoData(): CamfilPdfInfoData {
    // eslint-disable-next-line ish-custom-rules/no-object-literal-type-assertion
    return {
      customer: this.pdfCurrentCustomer,
      customerId: this.pdfCurrentCustomer.customerNo,
    } as CamfilPdfInfoData;
  }

  pdfInfoContent(
    // meta: CamfilPdfMetaData,
    data: CamfilPdfInfoData = this.pdfInfoData,
    labels = this.pdfLabels
  ): TDocumentDefinitions['content'] {
    return [
      {
        style: 'header',
        layout: 'noBorders',
        margin: [0, 0, 0, 10],
        table: {
          widths: ['auto', '*'],
          body: [
            [
              {
                svg: '<svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><g fill="none" stroke="#00673e" stroke-linecap="round" stroke-miterlimit="10"><path d="M5 23v2H2.1c-.6 0-1.1-.5-1.1-1.1V2.1C1 1.5 1.5 1 2.1 1h21.8c.6 0 1.1.5 1.1 1.1v21.8c0 .6-.5 1.1-1.1 1.1H8.6v-2M1 12.7h11.1M12.2 5.8v15.3M24.9 13h-8.3"></path></g></svg>',
                fit: [20, 20],
                margin: [5, 3, 0, 3],
              },
              { text: data.title, margin: [10, 5, 0, 0] },
            ],
          ],
        },
      },
      {
        style: 'info',
        columns: [
          {
            width: '30%',
            table: {
              body: PdfHelper.pdfDataToTableCells(data, labels, {
                orderMark: 'orderMark',
                invoiceLabel: 'invoiceLabel',
                lastOrder: 'lastDeliveryDate',
                orderInterval: 'deliveryInterval',
                nextOrder: 'nextDeliveryDate',
                goodsAcceptanceNote: 'goodsAcceptanceNote',
                creationDate: ['creationDate', value => formatCamfilDate(value as string, this.translate.currentLang)],
                validToDate: ['validToDate', value => formatCamfilDate(value as string, this.translate.currentLang)],
              }),
            },
            layout: 'noBorders',
          },
          {
            width: '*',
            table: {
              body: [
                [labels.deliveryAddress, PdfHelper.pdfAddress(data.deliveryAddress, data.deliveryAddress?.countryCode)],
              ],
            },
            layout: 'noBorders',
          },
          {
            width: '30%',
            table: {
              body: [
                [{ text: labels.customerAccount }, PdfHelper.pdfCustomer(data?.customer)],
                ...PdfHelper.pdfDataToTableCells(data, labels, {
                  requestedBy: 'requestedBy',
                  phoneNotification: 'phoneNotification',
                  contactPerson: 'contactPerson',
                  deliveryTerms: 'deliveryTerms',
                  paymentTerms: 'paymentTerms',
                }),
                [{ text: labels.note }, { text: data.note || CAMFIL_EMPTY_OPTION_LABEL, bold: true }],
              ],
            },
            layout: 'noBorders',
          },
        ],
      },
    ];
  }

  pdfTableLineContent<T extends CamfilPdfTableRowData>(
    meta: CamfilPdfMetaData,
    rowItem: T,
    labels = this.pdfLabels
  ): TableCell[] {
    const arrLeftInfo: TableCell[] = [
      { text: [{ text: labels.artNr }, { text: { text: rowItem?.sku, bold: true } }] },
      {
        text:
          rowItem?.leadTime !== '0'
            ? [{ text: labels.leadTime }, { text: { text: rowItem.leadTime, bold: true } }]
            : '',
      },
      {
        stack: [
          rowItem?.boxLabel && { text: [labels.boxLabel, { text: rowItem?.boxLabel, bold: true }] },
          rowItem?.measurements && { text: [labels?.measurements, rowItem?.measurements] },
        ],
      },
    ];

    const arrRightInfo: TableCell[] = [{ text: labels.quantity }, { text: rowItem.quantity }];

    if (meta?.showPrices) {
      arrRightInfo.push(labels.price, formatPrice(rowItem?.price, this.translate.currentLang));
    }

    return PdfHelper.pdfTableLine(
      rowItem?.index,
      rowItem?.name,
      rowItem?.url,
      rowItem.id,
      arrLeftInfo,
      arrRightInfo,
      meta?.showPrices
    );
  }

  pdfTableHeaderContent<T extends CamfilPdfTableRowData>(rowItem: T): TableCell[] {
    if (!rowItem?.levelName) {
      return;
    }

    return [{ text: rowItem.levelName, fillColor: '#F2F2F2', colSpan: 3, style: 'subHeader' }, '', ''];
  }

  pdfTableContent(meta: CamfilPdfMetaData, data: CamfilPdfTableData, labels = this.pdfLabels): ContentTable[][] {
    const uniqueLevels = [...new Set(data?.rows.map(rowItem => rowItem.level))];
    const tables = uniqueLevels.map(level => data.rows.filter(row => row.level === level));

    return tables.map(rows => {
      const tableHasHeader = rows.filter(row => row?.levelName).length > 0;
      const tableHeader = tableHasHeader ? this.pdfTableHeaderContent(rows?.[0]) : undefined;
      const tableBody = rows.map(row => this.pdfTableLineContent(meta, row, labels));

      if (tableHasHeader && tableHeader) {
        tableBody.unshift(tableHeader);
      }

      return [
        {
          table: {
            body: tableBody,
            widths: [15, '*', '28%'],
            dontBreakRows: true,
          },
          style: 'row',
          layout: {
            vLineWidth() {
              return 0;
            },
            hLineWidth() {
              return 0;
            },
            hLineColor() {
              return '#F2F2F2';
            },
          },
        },
      ];
    });
  }

  pdfTotalsContent(data: CamfilPdfTableData, labels = this.pdfLabels) {
    const total = data.rows.reduce((acc, row) => {
      const price = formatSummaryPrice(row?.price, this.pdfCurrentPriceDisplayType);
      return acc + (price?.value || 0);
    }, UnitHelper.getDefaultPrice().value || 0);

    return PdfHelper.pdfTotal(labels.yourTotal, total.toFixed(2).toString());
  }

  createPdf(data: CamfilPdfDefinitions) {
    const pdfDefinition: TDocumentDefinitions = {
      pageBreakBefore: (currentNode, _followingNodesOnPage, nodesOnNextPage) => {
        const start = currentNode?.id?.replace('rowIndex', '')?.replace('rowName', '');
        return !!nodesOnNextPage.find(node => node?.id === `rowEnd${start}`);
      },
      defaultStyle: this.pdfDefaultStyle,
      content: data?.content,
      styles: data?.styles,
      images: data?.images,
      footer: data?.showFooter
        ? (currentPage: number, pageCount: number) => [
            { text: `${currentPage.toString()}/${pageCount}`, alignment: 'center' },
          ]
        : undefined,
    };

    // eslint-disable-next-line unicorn/no-null
    return this.pdfMakeInstance.createPdf(pdfDefinition, null, this.pdfFonts);
  }
}
