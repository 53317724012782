<ul
  *ngIf="navigationCategories$ | async as categories"
  class="category-level{{ subCategoriesDepth }}"
  [ngClass]="{ 'dropdown-menu': subCategoriesDepth === 1, 'all-filters-menu': showAllFiltersBtn }"
>
  <li *ngIf="showAllFiltersBtn" class="main-navigation-btn-item">
    <a [routerLink]="url" mat-stroked-button>
      {{ 'camfil.navigation.see.all.airFilters' | translate }}
    </a>
  </li>
  <li
    *ngFor="let subcategory of categories"
    class="main-navigation-level{{ subCategoriesDepth }}-item"
    [ngClass]="{ open: isOpened(subcategory.uniqueId) }"
  >
    <a class="text-wrap" [routerLink]="subcategory.url" [ngStyle]="{ width: !subcategory.hasChildren ? '100%' : '' }">{{
      subcategory.name
    }}</a>
    <a
      *ngIf="subcategory.hasChildren"
      (click)="toggleOpen(subcategory.uniqueId)"
      (keydown.enter)="toggleOpen(subcategory.uniqueId)"
      tabindex="0"
      class="dropdown-toggle"
    >
      <fa-icon *ngIf="isOpened(subcategory.uniqueId); else closed" [icon]="['fas', 'minus']" />
      <ng-template #closed> <fa-icon [icon]="['fas', 'plus']" /> </ng-template>
    </a>
    <camfil-subcategory-navigation
      *ngIf="subCategoriesDepth < mainNavigationMaxSubCategoriesDepth && subcategory.hasChildren"
      [categoryUniqueId]="subcategory.uniqueId"
      [subCategoriesDepth]="subCategoriesDepth + 1"
    />
  </li>
</ul>
