import { DOCUMENT } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CamfilConfigurationFacade } from 'camfil-core/facades/camfil-configuration.facade';
import { unpackHeap } from 'camfil-core/utils/core/camfil-operators';
import { StatePropertiesService } from 'camfil-core/utils/state-transfer/state-properties.service';
import { AhuSupportMessage } from 'camfil-models/camfil-ahu/ahu.model';
import { ManufacturerData } from 'camfil-models/camfil-ahu/manufacturer/manufacturer.interface';
import { ManufacturerMapper } from 'camfil-models/camfil-ahu/manufacturer/manufacturer.mapper';
import { Manufacturer } from 'camfil-models/camfil-ahu/manufacturer/manufacturer.model';
import { UnitFilterData } from 'camfil-models/camfil-ahu/unit-filter/unit-filter.interface';
import { UnitFilterMapper } from 'camfil-models/camfil-ahu/unit-filter/unit-filter.mapper';
import { UnitFilter } from 'camfil-models/camfil-ahu/unit-filter/unit-filter.model';
import { UnitData } from 'camfil-models/camfil-ahu/unit/unit.interface';
import { UnitMapper } from 'camfil-models/camfil-ahu/unit/unit.mapper';
import { Unit } from 'camfil-models/camfil-ahu/unit/unit.model';
import { Observable, combineLatest, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApiService } from 'ish-core/services/api/api.service';

@Injectable({ providedIn: 'root' })
export class AhuService {
  constructor(
    private apiService: ApiService,
    private configurationFacade: CamfilConfigurationFacade,
    private stateProperties: StatePropertiesService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  constructAhuHttpClientParams() {
    const baseUrl = this.document.location.origin.replace(/\/+$/, '');

    const ahuEndpoint = '/ahu/v2';

    const path$ = this.stateProperties
      .getStateOrEnvOrDefault<string>('ICC_SERVER', 'iccServer')
      .pipe(map(iccEndpoint => [baseUrl, '/', iccEndpoint, ahuEndpoint].join('')));

    const params$ = this.configurationFacade.countryCode$.pipe(
      map(countryCode => new HttpParams().set('Market', countryCode))
    );

    return combineLatest([path$, params$]).pipe(map(([path, params]) => ({ path, params })));
  }

  /**
   * List manufacturers for a current channel/market.
   */
  getManufacturers(): Observable<Manufacturer[]> {
    return this.constructAhuHttpClientParams().pipe(
      switchMap(({ path, params }) =>
        this.apiService.get<ManufacturerData[]>(`${path}/manufacturers`, { params }).pipe(
          unpackHeap<ManufacturerData>(),
          map(data => ManufacturerMapper.fromListData(data, 'Name'))
        )
      )
    );
  }

  /**
   * Get air handling units.
   * @param manufacturerId
   */
  getUnits(manufacturerId: string): Observable<Unit[]> {
    if (!manufacturerId) {
      return throwError(() => new Error('getUnits() called without manufacturerId'));
    }

    return this.constructAhuHttpClientParams().pipe(
      switchMap(({ path, params }) =>
        this.apiService
          .get<UnitData[]>(`${path}/units`, {
            params: params.set('ManufacturerId', manufacturerId),
          })
          .pipe(
            unpackHeap<UnitData>(),
            map(data => UnitMapper.fromListData(data, 'Name')),
            // Workaround to pass `manufacturerId` to Unit cuz it is not included in API response.
            map(units => UnitMapper.mapByManufacturerId(units, manufacturerId))
          )
      )
    );
  }

  /**
   * Get air handling unit filters.
   *
   * @param manufacturerId
   * @param unitId
   */
  getUnitFilters(manufacturerId: string, unitId: number): Observable<UnitFilter> {
    if (!unitId && !manufacturerId) {
      return throwError(() => new Error('getUnitFilters() called without unitId & manufacturerId'));
    }

    return this.constructAhuHttpClientParams().pipe(
      switchMap(({ path, params }) =>
        this.apiService
          .get<UnitFilterData>(`${path}/filters`, {
            params: params.set('ManufacturerId', manufacturerId).set('UnitId', `${unitId}`),
          })
          .pipe(
            map(data => UnitFilterMapper.fromData(data)),
            // Workaround to pass `manufacturerId` to Unit cuz it is not included in API response.
            map(unitFilter => UnitFilterMapper.mapByManufacturerId(unitFilter, manufacturerId))
          )
      )
    );
  }

  /**
   * Request an email to Camfil support from AHU guide.
   * @param data
   */
  sendAhuRequestComment(data: AhuSupportMessage) {
    return this.apiService.post('mailing/support-request', data);
  }
}
