import { Pipe, PipeTransform } from '@angular/core';
import { CamfilCustomer } from 'camfil-models/camfil-customer/camfil-customer.model';
import { CAMFIL_EMPTY_OPTION_LABEL } from 'camfil-shared/camfil-constants';

export function formatCamfilCustomer(c: CamfilCustomer): string {
  const properties = [c?.department, c?.customerNo, c?.companyName];

  // eslint-disable-next-line unicorn/no-null
  if (properties.every(prop => prop === null || prop === undefined || prop === CAMFIL_EMPTY_OPTION_LABEL)) {
    return CAMFIL_EMPTY_OPTION_LABEL;
  }

  return (
    properties
      // eslint-disable-next-line unicorn/no-null
      .filter(prop => prop !== null && prop !== undefined && prop !== CAMFIL_EMPTY_OPTION_LABEL)
      .join(', ')
  );
}

@Pipe({ name: 'camfilCustomer', pure: true })
export class CamfilCustomerPipe implements PipeTransform {
  transform(c: CamfilCustomer): string {
    return formatCamfilCustomer(c);
  }
}
