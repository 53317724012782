import { createSelector } from '@ngrx/store';

import { getCustomerState } from 'ish-core/store/customer/customer-store';

import { getCamfilFeatureState } from '../camfil-store';

import { CamfilBucketsState, camfilBucketFeatureKey, camfilBucketsAdapter } from './camfil-bucket.reducer';

const getCamfilBucketsState = getCamfilFeatureState<CamfilBucketsState>(camfilBucketFeatureKey);

export const getCamfilBucketsLoading = createSelector(getCamfilBucketsState, state => state.loading);

const { selectAll } = camfilBucketsAdapter.getSelectors(getCamfilBucketsState);

export const getCurrentCamfilBuckets = selectAll;

export const getCamfilBucketByAddressId = (addressId: string) =>
  createSelector(getCurrentCamfilBuckets, camfilBuckets =>
    camfilBuckets?.find(u => u?.deliveryAddressId === addressId || u.deliveryAddress?.id === addressId)
  );

export const selectCamfilEmailRecipients = createSelector(getCurrentCamfilBuckets, camfilBuckets =>
  camfilBuckets.map(({ emailRecipients, shippingAddress }) => ({
    urn: shippingAddress?.id,
    emailRecipients,
  }))
);

export const getFreightCostInvalid = createSelector(getCurrentCamfilBuckets, camfilBuckets =>
  camfilBuckets
    ?.map(({ freightCostInvalid }) => ({
      freightCostInvalid,
    }))
    .some(({ freightCostInvalid }) => freightCostInvalid)
);

export const getCamfilBucketsLength = createSelector(
  getCurrentCamfilBuckets,
  camfilBuckets => camfilBuckets?.length || 0
);

export const getEmptyCamfilBuckets = createSelector(getCurrentCamfilBuckets, camfilBuckets =>
  camfilBuckets.filter(cb => cb.isEmpty)
);

const getCamfilBucketDeliveryAddress = createSelector(getCurrentCamfilBuckets, camfilBuckets =>
  camfilBuckets.map(cb => cb.deliveryAddress)
);

export const getBucketGoodsAcceptanceNote = (addressId: string) =>
  createSelector(
    getCamfilBucketDeliveryAddress,
    allAddress => allAddress?.find(ar => ar.id === addressId)?.goodsAcceptanceNote || ''
  );

const getBasketState = createSelector(getCustomerState, state => state?.basket);

export const getCamfilBucketsVolumeDiscounts = createSelector(
  getBasketState,
  getCurrentCamfilBuckets,
  (basketState, camfilBuckets) => {
    if (!basketState?.basket) {
      return {
        type: 'Money' as const,
        currency: 'N/A',
        value: 0,
      };
    }

    const totalVolumeDiscount = camfilBuckets?.map(be => be.volumeDiscount).reduce((prev, next) => prev + next, 0);

    return {
      type: 'Money' as const,
      currency: basketState.basket.purchaseCurrency,
      value: totalVolumeDiscount,
    };
  }
);

export const getCurrentCamfilBucketsItems = createSelector(getCurrentCamfilBuckets, camfilBuckets =>
  camfilBuckets?.reduce((items, b) => [...items, ...b.lineItems], [])
);

export const getSubmittedBuckets = createSelector(getCamfilBucketsState, state => state.submitedBucket);

export const getPunchoutEmptyBucket = createSelector(getCamfilBucketsState, state => state.punchoutEmptyBucket);
